import React from 'react'

import ProductBlockHorizontal from '../../../react4xp/components/ProductBlockHorizontal'

function ProductCardPart({ title, intro, position, tilt, url, sticker, image }) {
    return (
        <ProductBlockHorizontal
            section={{
                bgColor: 'white'
            }}
            productPosition={position}
            tilt={tilt}
            href={url.href}
            external={url.isExternal}
            icon={{
                position: sticker.position,
                src: sticker.type
            }}
            src={image?.link}
            alt={image?.alt}
            title={title}
            desc={intro}
        />
    )
}

export default (props) => <ProductCardPart {...props} />
